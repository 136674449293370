<div class="text-center mt-4">
	<h2 class="title m-0">
		{{ 'AUTH.TFA_WELLCOME' | translate }}
	</h2>
</div>

<div [formGroup]="form" class="p-6 flex flex-col gap-4">
	<input type="hidden" formControlName="email" />
	<input type="hidden" formControlName="password" />
	<div class="flex flex-col">
		<mat-form-field class="flex-1">
			<mat-label> {{ 'AUTH.TFA_PROVIDER' | translate }} </mat-label>
			<mat-select formControlName="provider">
				<mat-option [value]="'Email'" [disabled]="true"> {{ 'AUTH.TFA_PROVIDER_EMAIL' | translate }} </mat-option>
				<mat-option [value]="'Phone'" [disabled]="true"> {{ 'AUTH.TFA_PROVIDER_PHONE' | translate }} </mat-option>
				<mat-option [value]="'Authenticator'"> {{ 'AUTH.TFA_PROVIDER_AUTHENTICATOR' | translate }} </mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="flex flex-col">
		<mat-form-field class="flex-1">
			<mat-label> {{ 'AUTH.TFA_CODE' | translate }} </mat-label>
			<input formControlName="code" matInput autocomplete="new-password" />
		</mat-form-field>
	</div>

	<button (click)="submit()" color="primary" mat-raised-button type="button">
		{{ 'AUTH.LOGIN' | translate }}
	</button>
</div>
