import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'formly-file-field',
	standalone: true,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		FormsModule,

		MatSelectModule,
		MatInputModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,
		MatIconModule,

		CommonModule,
		KedyPipesModule
	],
	template: `
		<mat-form-field appearance="outline" style="width: 100%;">
			<mat-label> {{ field.props?.label }} </mat-label>
			<input
				#f_input
				type="file"
				[formControl]="control"
				[formlyAttributes]="field"
				hidden
				(change)="handleFileInputChange(f_input.files)"
			/>
			<input [(ngModel)]="fileName" type="text" readonly matInput />
			<mat-icon matSuffix svgIcon="mat:attach_file" (click)="f_input.click()" class="cursor-pointer"></mat-icon>

			@if (control.hasError('required')) {
				<mat-error> {{ field.props?.label }} is required </mat-error>
			}
			@if (control.hasError('maxFileSize')) {
				<mat-error> File size should be less than 10MB </mat-error>
			}
		</mat-form-field>

		@if (form.value[field.key + 'Path']) {
			<a
				[href]="env.CDN_URL + form.value[field.key + 'Path']"
				target="_blank"
				class="cursor-pointer underline text-blue-500"
			>
				View File
			</a>
		}
	`
})
export class FileFieldComponent extends FieldType<FormlyFieldConfig> implements AfterViewInit {
	matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
	constructor(private matSnackBar: MatSnackBar) {
		super();
	}

	ngAfterViewInit(): void {}
	env: any = environment;
	fileName!: string;
	filePath!: string;
	get control() {
		return this.formControl as FormControl;
	}

	handleFileInputChange(files: FileList | null): void {
		if (!files) return;

		if (files.length == 1) {
			var file = files[0];
			this.fileName = file.name;
			if (!this.controlFileSize(file)) return;
			if (this.field.key && typeof this.field.key === 'string') this.model[this.field.key] = files;
		} else if (files.length > 1) {
			this.fileName = files.length + ' files selected';
			// foreach files and check size
			for (var i = 0; i < files.length; i++) {
				var file = files[i];
				if (!this.controlFileSize(file)) return;
			}
			if (this.field.key && typeof this.field.key === 'string') this.model[this.field.key] = files;
		} else {
			this.fileName = '';
		}
	}

	controlFileSize(file: File): boolean {
		if (file.size / 1024 / 1024 > 10) {
			this.control.setErrors({ maxFileSize: true });
			this.control.reset();
			this.control.setValue(null);
			this.fileName = '';
			this.matSnackBar.open("Dosya boyutu 10MB'dan büyük olamaz", 'Tamam', { duration: 3000 });
			return false;
		}
		return true;
	}
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { KedyPipesModule } from '../../pipes/kedy-pipes.module';
import path from 'path';
import { MatIconModule } from '@angular/material/icon';

@Directive({
	selector: 'input[type=file]',
	host: {
		'(change)': 'onChange($event.target.files)',
		'(blur)': 'onTouched()'
	},
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessor, multi: true }],
	standalone: true
})
export class FileValueAccessor implements ControlValueAccessor {
	value: any;
	onChange = (_: any) => {};
	onTouched = () => {};

	writeValue(_value: any) {}
	registerOnChange(fn: any) {
		this.onChange = fn;
	}
	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}
}
