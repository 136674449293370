import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	HttpClient,
	HttpClientModule,
	provideHttpClient,
	withInterceptors,
	withInterceptorsFromDi
} from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';
import { provideIcons } from './core/providers/icons.provider';
import { provideLuxon } from './core/providers/luxon.provider';
import { provideVex } from '@vex/vex.provider';
import { provideNavigation } from './core/providers/navigation.provider';
import { vexConfigs } from '@vex/config/vex-configs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { tokenInterceptor } from './core/interceptors/token.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CachedSelectFieldComponent } from './core/components/formly/cached-select.field';
import { MatFieldWrapper } from './core/components/formly/mat-field.wrapper';
import { unauthorizedErrorInterceptor } from './core/interceptors/unauthorized-error.interceptor';
import { FileFieldComponent } from './core/components/formly/file.field';
import { DatepickerFieldComponent } from './core/components/formly/datepicker.field';
import { provideQuillConfig } from 'ngx-quill';
import { EditorFieldComponent } from './core/components/formly/editor.field';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HttpSelectFieldComponent } from './core/components/formly/http-select.field';
import { registerAutoCompeteOnExtension } from './core/components/formly/autocomplete-on.extension';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(
			HttpClientModule,
			BrowserModule,
			MatDialogModule,
			MatBottomSheetModule,
			MatNativeDateModule,
			ReactiveFormsModule,
			FormlyPresetModule,
			FormlyModule.forRoot({
				types: [
					{ name: 'cached-select', component: CachedSelectFieldComponent },
					{ name: 'file', component: FileFieldComponent },
					{ name: 'datepicker', component: DatepickerFieldComponent },
					{ name: 'editor', component: EditorFieldComponent },
					{ name: 'http-select', component: HttpSelectFieldComponent }
				],
				wrappers: [{ name: 'mat-field', component: MatFieldWrapper }],
				presets: [
					{ name: 'name', config: { key: 'name', id: 'name', type: 'input', props: { label: 'İsim' } } },
					{
						name: 'reference',
						config: { key: 'reference', id: 'reference', type: 'input', props: { label: 'Referans' } }
					},
					{
						name: 'description',
						config: { key: 'description', id: 'description', type: 'input', props: { label: 'Açıklama' } }
					},
					{ name: 'barcode', config: { key: 'barcode', id: 'barcode', type: 'input', props: { label: 'Barkod' } } },
					{
						name: 'price',
						config: { key: 'price', type: 'input', props: { label: 'Fiyat', type: 'number', step: 0.01 } }
					},
					{
						name: 'discountRate',
						config: { key: 'discountRate', type: 'input', props: { label: 'İskonto', type: 'number' } }
					},
					{
						name: 'discount',
						config: { key: 'discount', type: 'input', props: { label: 'İskonto', type: 'number' } }
					},
					{
						name: 'amount',
						config: { key: 'amount', type: 'input', props: { label: 'Miktar', type: 'number', step: 1 } }
					},
					{
						name: 'quantity',
						config: { key: 'quantity', type: 'input', props: { label: 'Miktar', type: 'number' } }
					},
					{
						name: 'currency',
						config: {
							key: 'currencyId',
							type: 'select',
							props: {
								label: 'Kur',
								options: [
									{ label: 'TRY', value: 1 },
									{ label: 'EUR', value: 2 },
									{ label: 'USD', value: 3 }
								]
							}
						}
					},
					{
						name: 'brand',
						config: {
							key: 'brandId',
							type: 'cached-select',
							props: { label: 'Marka' }
						}
					}
				]
			}),
			FormlyMaterialModule
		),
		provideRouter(
			appRoutes,
			// TODO: Add preloading withPreloading(),
			withInMemoryScrolling({
				anchorScrolling: 'enabled',
				scrollPositionRestoration: 'enabled'
			})
		),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi(), withInterceptors([tokenInterceptor, unauthorizedErrorInterceptor])),

		provideVex({
			/**
			 * The config that will be used by default.
			 * This can be changed at runtime via the config panel or using the VexConfigService.
			 */
			config: vexConfigs.ikaros,

			/**
			 * Only themes that are available in the config in tailwind.config.ts should be listed here.
			 * Any theme not listed here will not be available in the config panel.
			 */
			availableThemes: [
				{
					name: 'Default',
					className: 'vex-theme-default'
				},
				{
					name: 'Teal',
					className: 'vex-theme-teal'
				},
				{
					name: 'Green',
					className: 'vex-theme-green'
				},
				{
					name: 'Purple',
					className: 'vex-theme-purple'
				},
				{
					name: 'Red',
					className: 'vex-theme-red'
				},
				{
					name: 'Orange',
					className: 'vex-theme-orange'
				}
			]
		}),
		provideNavigation(),
		provideIcons(),
		provideLuxon(),
		provideQuillConfig({
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					['blockquote', 'code-block'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['clean'],
					['link', 'image']
				]
			}
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				deps: [HttpClient],
				useFactory: HttpLoaderFactory
			}
		}).providers!,
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'outline' }
		}
		// {
		// 	provide: FORMLY_CONFIG,
		// 	multi: true,
		// 	useFactory: registerAutoCompeteOnExtension
		// }
	]
};
