<div class="text-center mt-4">
	<h2 class="title m-0">
		{{ 'AUTH.WELLCOME' | translate }}
	</h2>
</div>

<div [formGroup]="form" class="p-6 flex flex-col gap-4">
	<div class="flex flex-col">
		<mat-form-field class="flex-1">
			<mat-label> {{ 'AUTH.EMAIL' | translate }} </mat-label>
			<input formControlName="email" matInput required />
			@if (form.get('email')?.hasError('required')) {
				<mat-error>
					{{ 'AUTH.EMAIL_REQUIRED' | translate }}
				</mat-error>
			}
		</mat-form-field>

		<mat-form-field class="flex-1">
			<mat-label> {{ 'AUTH.PASSWORD' | translate }} </mat-label>
			<input [type]="inputType" formControlName="password" matInput required />
			<button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
				@if (visible) {
					<mat-icon svgIcon="mat:visibility"></mat-icon>
				} @else {
					<mat-icon svgIcon="mat:visibility_off"></mat-icon>
				}
			</button>
			@if (form.get('password')?.hasError('required')) {
				<mat-error>
					{{ 'AUTH.PASSWORD_REQUIRED' | translate }}
				</mat-error>
			}
		</mat-form-field>
	</div>

	<div class="flex items-center justify-between">
		<div></div>
		<a class="caption" href="/forgot-password">
			{{ 'AUTH.FORGOT_PASSWORD' | translate }}
		</a>
	</div>

	<button (click)="submit()" color="primary" mat-raised-button type="button">
		{{ 'AUTH.LOGIN' | translate }}
	</button>
</div>
