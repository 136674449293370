import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { trackById } from '@vex/utils/track-by';
import { VexPopoverRef } from '@vex/components/vex-popover/vex-popover-ref';
import { RouterLink } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';
import { TranslateModule } from '@ngx-translate/core';

export interface OnlineStatus {
	id: 'online' | 'away' | 'dnd' | 'offline';
	label: string;
	icon: string;
	colorClass: string;
}

@Component({
	selector: 'vex-toolbar-user-dropdown',
	templateUrl: './toolbar-user-dropdown.component.html',
	styleUrls: ['./toolbar-user-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		MatMenuModule,
		NgFor,
		MatRippleModule,
		RouterLink,
		NgClass,
		NgIf,
		TranslateModule
	]
})
export class ToolbarUserDropdownComponent implements OnInit {
	trackById = trackById;
	loggedUserName: string = '';

	constructor(
		private cd: ChangeDetectorRef,
		private popoverRef: VexPopoverRef<ToolbarUserDropdownComponent>,
		private authenticatorService: AuthenticatorService
	) {}

	ngOnInit() {
		var user = this.authenticatorService.userSubject.value;
		if (user) {
			this.loggedUserName = user.firstName + ' ' + user.lastName;
		}
	}

	close() {
		this.popoverRef.close();
	}
}
