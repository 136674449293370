import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthenticatorSetup } from 'src/app/core/models/authenticator-setup.interface';
import { Response } from 'src/app/core/models/response.interface';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
	selector: 'tfa-setup',
	standalone: true,
	imports: [
		ReactiveFormsModule,

		MatProgressBarModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,

		QRCodeModule
	],
	templateUrl: './tfa-setup.component.html'
})
export class TfaSetupComponent {
	loading: boolean = false;
	is2faEnabled: boolean = false;

	authenticatorSetupLoading: boolean = false;
	authenticatorSetup: AuthenticatorSetup | null = null;

	authenticatorVerifyLoading: boolean = false;

	form: UntypedFormGroup = this.fb.group({
		code: ['']
	});

	constructor(
		private apiService: ApiService,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.apiService.get<Response<boolean>>('identity/is-2fa-enabled').subscribe((res: any) => {
			this.is2faEnabled = res.data;
			if (!this.is2faEnabled) this.loadAuthenticatorSetup();
			this.loading = false;
			this.cdr.detectChanges();
		});
	}

	isAuthenticatorSetupLoaded = false;
	loadAuthenticatorSetup() {
		if (this.isAuthenticatorSetupLoaded) return;
		this.apiService.get<Response<AuthenticatorSetup>>('identity/setup').subscribe((res: any) => {
			this.authenticatorSetup = res.data;
			this.authenticatorSetupLoading = false;
			this.cdr.detectChanges();
		});
	}

	authenticatorVerify(enabled: boolean) {
		this.authenticatorVerifyLoading = true;
		this.apiService
			.post<Response>('identity/verify', {
				code: this.form.value.code,
				enabled: enabled
			})
			.subscribe((res: any) => {
				this.authenticatorVerifyLoading = false;
				this.is2faEnabled = enabled;
				if (!enabled) this.loadAuthenticatorSetup();
				this.cdr.detectChanges();
			});
	}
}
