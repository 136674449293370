<div [class.container]="isVerticalLayout$ | async" class="toolbar text-default w-full px-6 flex items-center">
	<button (click)="openSidenav()" [class.hidden]="isDesktop$ | async" mat-icon-button type="button">
		<mat-icon svgIcon="mat:menu"></mat-icon>
	</button>

	<a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 flex items-center">
		<img alt="Logo" class="w-10 select-none" src="/assets/img/logo.png" />
		<h1 [class.hidden]="isDesktop$ | async" class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none">
			{{ title$ | async }}
		</h1>
	</a>

	<div
		*ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
		[class.hidden]="!(isDesktop$ | async)"
		class="px-6 flex-none flex items-center"
	>
		<vex-navigation-item *ngFor="let item of navigationItems$ | async" [item]="item"></vex-navigation-item>
	</div>

	<span class="flex-1"></span>

	<div class="-mx-1 flex items-center">
		<div *ngIf="userVisible$ | async" class="px-1">
			<vex-toolbar-user></vex-toolbar-user>
		</div>
	</div>
</div>

<vex-navigation
	*ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
	[class.hidden]="!(isDesktop$ | async)"
></vex-navigation>

<!-- <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
	<button (click)="changeLang('en')" mat-menu-item>
		<div class="flex items-center">
			<img class="w-5 h-5 mr-2" [src]="'/assets/img/flags/en.png'" alt="English" />
			<div class="flex-1"></div>
			<span>English</span>
		</div>
	</button>

	<button (click)="changeLang('tr')" mat-menu-item>
		<div class="flex items-center">
			<img class="w-5 h-5 mr-2" [src]="'/assets/img/flags/tr.png'" alt="Türkçe" />
			<div class="flex-1"></div>
			<span>Türkçe</span>
		</div>
	</button>
</mat-menu> -->
