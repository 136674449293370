import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { trackByValue } from '../../utils/track-by';
import { VexBreadcrumbComponent } from './vex-breadcrumb/vex-breadcrumb.component';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslatorService } from 'src/app/core/services/translator.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'vex-breadcrumbs',
	template: `
		<div class="flex items-center gap-2">
			<vex-breadcrumb>
				<a [routerLink]="['/']">
					<mat-icon svgIcon="mat:home" class="icon-sm"></mat-icon>
				</a>
			</vex-breadcrumb>
			<ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
				<div class="w-1 h-1 bg-gray-600 rounded-full"></div>
				<vex-breadcrumb>
					<a [routerLink]="[]">
						@if (crumb.startsWith('i18n')) {
							{{ crumb.split(':')[1] | translate }}
						} @else {
							{{ crumb }}
						}
					</a>
				</vex-breadcrumb>
			</ng-container>
		</div>
	`,
	standalone: true,
	imports: [VexBreadcrumbComponent, RouterLink, NgFor, MatIconModule, TranslateModule]
})
export class VexBreadcrumbsComponent {
	@Input() crumbs: string[] = [];

	trackByValue = trackByValue;
}
