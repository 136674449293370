import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccessToken } from 'src/app/core/models/access-token.interface';
import { Response } from 'src/app/core/models/response.interface';
import { UserTfaLogin } from 'src/app/core/models/user-login.interface';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';
import { TranslatorService } from 'src/app/core/services/translator.service';

@Component({
	selector: 'tfa',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,

		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatButtonModule
	],
	templateUrl: './tfa.component.html'
})
export class TfaComponent implements OnInit {
	form: UntypedFormGroup = this.fb.group({
		provider: ['Authenticator', Validators.required],
		code: ['', Validators.required]
	});
	loading = false;

	state: any = {};
	constructor(
		private router: Router,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private snackbar: MatSnackBar,
		private authService: AuthenticatorService,
		private apiService: ApiService,
		private translator: TranslatorService
	) {
		var currentNavigation = this.router.getCurrentNavigation();
		if (!currentNavigation) {
			this.router.navigateByUrl('/identity/login');
			return;
		}
		if (!currentNavigation.extras.state) {
			this.router.navigateByUrl('/identity/login');
			return;
		}
		this.state = currentNavigation.extras.state;
	}

	ngOnInit(): void {
		if (!this.state['email'] || !this.state['password']) {
			this.router.navigateByUrl('/identity/login');
			return;
		}
	}

	submit() {
		this.loading = true;
		var model: UserTfaLogin = {
			email: this.state.email,
			password: this.state.password,
			provider: this.form.value.provider,
			code: this.form.value.code
		};
		this.apiService.post<Response<AccessToken>>(`identity/login-with-2fa`, model).subscribe({
			next: (response) => {
				if (response.data.tokenType == 'AccessToken') {
					this.authService.setCredentials(response.data);
					this.router.navigateByUrl('/');
					return;
				} else if (response.data.tokenType == 'ResetPasswordToken') {
					this.snackbar.open(this.translator.translate('AUTH.NEED_RESET_PASSWORD'), this.translator.translate('OK'));
					this.router.navigateByUrl('/identity/reset-password', {
						state: { email: this.state.email, token: response.data.token }
					});
					return;
				}
			},
			error: (error) => {
				this.snackbar.open(error.error.message, this.translator.translate('OK'));
			},
			complete: () => {
				this.loading = false;
				this.cdr.markForCheck();
			}
		});
	}
}
