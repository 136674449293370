import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom, take, tap } from 'rxjs';
import { LookupService } from './lookup.service';

export interface EntityCacheInformation {
	entityName: string;
	value: string;
	observable: Observable<any[]>;
	cacheTime?: number;
}

export interface EntityCacheInformationLabel extends EntityCacheInformation {
	label?: string;
}

export interface EntityCacheInformationLabelFunc extends EntityCacheInformation {
	labelFunc?: (entity: any) => string;
}

export interface EntityInformation {
	entityName: string;
	cacheTime?: number;
	data: any[];
	entityLabels: EntityLabel[];
}

export interface EntityLabel {
	id: number;
	data: any;
	label: string;
}

@Injectable({
	providedIn: 'root'
})
export class EntityService {
	brandhEntityInformation: EntityCacheInformationLabel = {
		entityName: 'Brand',
		observable: this.lookupService.brands$,
		value: 'id',
		label: 'name'
	};
	customerEntityInformation: EntityCacheInformationLabel = {
		entityName: 'Customer',
		observable: this.lookupService.customers$,
		value: 'id',
		label: 'name'
	};
	currencyEntityInformation: EntityCacheInformationLabel = {
		entityName: 'Currency',
		observable: this.lookupService.currencies$,
		value: 'id',
		label: 'name'
	};
	userEntityInformation: EntityCacheInformationLabelFunc = {
		entityName: 'User',
		observable: this.lookupService.users$,
		value: 'id',
		labelFunc: (entity) => entity.firstName + ' ' + entity.lastName
	};
	entityCacheInformations: Array<EntityCacheInformationLabel | EntityCacheInformationLabelFunc> = [
		this.brandhEntityInformation,
		this.customerEntityInformation,
		this.currencyEntityInformation,
		this.userEntityInformation
	];

	constructor(private lookupService: LookupService) {}

	private getEntityInformation(entityName: string): EntityCacheInformationLabel | EntityCacheInformationLabelFunc {
		var entityInformation = this.entityCacheInformations.find((x) => x.entityName == entityName);
		if (!entityInformation) {
			throw new Error(`Entity ${entityName} not found`);
		}
		return entityInformation;
	}

	async getEntityLabel(entityName: string, id: number): Promise<string> {
		var entityInformation = this.getEntityInformation(entityName);

		var entity = await this.getEntity(entityName, id);

		// if entityInformation is EntityCacheInformationLabelFunc then return labelFunc(entity)
		if ((entityInformation as EntityCacheInformationLabelFunc).labelFunc) {
			var labelFunc = (entityInformation as EntityCacheInformationLabelFunc).labelFunc;
			if (!labelFunc) {
				throw new Error('labelFunc is required');
			}
			return labelFunc(entity);
		}
		if ((entityInformation as EntityCacheInformationLabel).label) {
			var label = (entityInformation as EntityCacheInformationLabel).label;
			if (!label) {
				throw new Error('label is required');
			}
			return entity[label];
		}

		throw new Error(`Label not found for entity ${entityName}`);
	}

	async getEntities(entityName: string): Promise<any[]> {
		var entityInformation = this.getEntityInformation(entityName);

		var entities = await lastValueFrom(entityInformation.observable.pipe(take(1)));
		return entities;
	}

	async getEntity(entityName: string, id: number): Promise<any> {
		var entities = await this.getEntities(entityName);

		var entity = entities.find((x) => x[this.getEntityInformation(entityName).value] == id);
		return entity;
	}
}
