import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from './custom-formly-field.interface';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
	selector: 'formly-custom-select',
	imports: [FormlyModule, ReactiveFormsModule, MatFormFieldModule, QuillEditorComponent],
	standalone: true,
	template: `
		<mat-label>{{ to.label }}</mat-label>
		<quill-editor
			[styles]="{ flex: '1 1 0', height: '300px' }"
			class="w-full"
			[formControl]="control"
			[formlyAttributes]="field"
		></quill-editor>
	`,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['../../../../../node_modules/quill/dist/quill.snow.css']
})
export class EditorFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit {
	ngOnInit(): void {}

	get control() {
		return this.formControl as FormControl;
	}
}
