import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationDropdown, NavigationItem } from '../models/navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticatorService } from './authenticator.service';

@Injectable({
	providedIn: 'root'
})
export class NavigationLoaderService {
	private readonly _items: BehaviorSubject<NavigationItem[]> = new BehaviorSubject<NavigationItem[]>([]);

	get items$(): Observable<NavigationItem[]> {
		return this._items.asObservable();
	}

	constructor(
		private readonly layoutService: VexLayoutService,
		private authService: AuthenticatorService
	) {
		this.loadNavigation();
	}

	loadNavigation(): void {
		var items: NavigationItem[] = [];
		items.push({
			type: 'link',
			label: 'Projeler',
			route: '/project/filter',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanSeeProjects'
		});
		items.push({
			type: 'link',
			label: 'Depo',
			route: '/warehouse',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanEditWarehouse'
		});
		items.push({
			type: 'link',
			label: 'Müșteriler',
			route: '/customer/filter',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanEditCustomers'
		});
		items.push({
			type: 'link',
			label: 'Markalar',
			route: '/brand/filter',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanEditBrands'
		});
		items.push({
			type: 'dropdown',
			label: 'Ürünler',
			claim: 'CanEditProducts',
			disabled: true,
			children: [
				{
					type: 'link',
					label: 'Ürün Listesi',
					route: '/product/filter',
					routerLinkActiveOptions: { exact: true }
				},
				{
					type: 'link',
					label: 'İçeri Aktar',
					route: '/product/import',
					routerLinkActiveOptions: { exact: true }
				},
				{
					type: 'link',
					label: 'Fiyat Güncelle',
					route: '/product/update-prices',
					routerLinkActiveOptions: { exact: true }
				},
				{
					type: 'link',
					label: 'En Çok Kullanılan Ürünler',
					route: '/product/most-used',
					routerLinkActiveOptions: { exact: true }
				},
				{
					type: 'link',
					label: 'Ürün Karşılaştırma',
					route: '/product/compare',
					routerLinkActiveOptions: { exact: true }
				},
				{
					type: 'link',
					label: 'Muadil Gruplari',
					route: '/product-equivalent/filter',
					routerLinkActiveOptions: { exact: true }
				}
			]
		});

		var settings: NavigationDropdown = {
			type: 'dropdown',
			label: 'Ayarlar',
			claim: 'CanEditSettings',
			children: []
		};
		settings.children.push({
			type: 'link',
			label: 'Bakır Panelleri',
			route: '/copper-panel/filter',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanEditCopperPanels'
		});
		settings.children.push({
			type: 'link',
			label: 'Hazır Paneller',
			route: '/constant-panel/filter',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanEditConstantPanels'
		});
		settings.children.push({
			type: 'link',
			label: 'Kullanıcılar',
			route: '/user/filter',
			routerLinkActiveOptions: { exact: true },
			claim: 'CanEditUsers'
		});
		settings.children.push({
			type: 'link',
			label: 'Roller',
			route: '/role/filter',
			routerLinkActiveOptions: { exact: true }
		});
		items.push(settings);
		this._items.next(items);
	}
}
