import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'enum'
})
export class EnumPipe implements PipeTransform {
	constructor(private translateService: TranslateService) {}

	async transform(value: number, key: string): Promise<string> {
		return this.translateService.instant(`enum.${key}.${value}`);
	}
}
