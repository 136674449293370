import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CurrencyPipe } from './currency.pipe';
import { SearchPipe } from './search.pipe';
import { EntityPipe } from './entity.pipe';
import { EnumPipe } from './enum.pipe';

@NgModule({
	declarations: [CurrencyPipe, SearchPipe, EntityPipe, EnumPipe],
	exports: [CurrencyPipe, SearchPipe, EntityPipe, EnumPipe],
	providers: [DecimalPipe],
	imports: [CommonModule]
})
export class KedyPipesModule {}
