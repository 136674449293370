import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './identity/login/login.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { TfaComponent } from './identity/tfa/tfa.component';
import { ResetPasswordComponent } from './identity/reset-password/reset-password.component';
import { LogoutComponent } from './identity/logout/logout.component';
import { ProfileComponent } from './identity/profile/profile.component';

export const appRoutes: VexRoutes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				redirectTo: '/project/filter',
				pathMatch: 'full'
			},
			{
				path: 'profile',
				component: ProfileComponent
			},
			{
				path: 'project',
				loadChildren: () => import('./pages/project/project.module').then((m) => m.ProjectModule)
			},
			{
				path: 'offer',
				loadChildren: () => import('./pages/offer/offer.module').then((m) => m.OfferModule)
			},
			{
				path: 'brand',
				loadChildren: () => import('./pages/brand/brand.module').then((m) => m.BrandModule)
			},
			{
				path: 'product',
				loadChildren: () => import('./pages/product/product.module').then((m) => m.ProductModule)
			},
			{
				path: 'product-equivalent',
				loadChildren: () =>
					import('./pages/product-equivalent/product-equivalent.module').then((m) => m.ProductEquivalentModule)
			},
			{
				path: 'copper-panel',
				loadChildren: () => import('./pages/copper-panel/copper-panel.module').then((m) => m.CopperPanelModule)
			},
			{
				path: 'role',
				loadChildren: () => import('./pages/role/role.module').then((m) => m.RoleModule)
			},
			{
				path: 'user',
				loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule)
			},
			{
				path: 'customer',
				loadChildren: () => import('./pages/customer/customer.module').then((m) => m.CustomerModule)
			},
			{
				path: 'constant-panel',
				loadChildren: () => import('./pages/constant-panel/constant-panel.module').then((m) => m.ConstantPanelModule)
			},
			{
				path: 'stock',
				loadChildren: () => import('./pages/stock/stock.module').then((m) => m.StockModule)
			},
			{
				path: 'warehouse',
				loadChildren: () => import('./pages/warehouse/warehouse.module').then((m) => m.WarehouseModule)
			}
		]
	},
	{
		path: 'identity',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent
			},
			{
				path: 'tfa',
				component: TfaComponent
			},
			{
				path: 'logout',
				component: LogoutComponent
			},
			{
				path: '**',
				redirectTo: 'login'
			}
		]
	}
];
