import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from './custom-formly-field.interface';

@Component({
	selector: 'formly-custom-select',
	imports: [FormlyModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule],
	standalone: true,
	template: `
		<mat-form-field appearance="outline" style="width: 100%;">
			<mat-label> {{ field.props?.label }} </mat-label>
			<input
				[matDatepicker]="datepickerRef"
				[matDatepickerFilter]="field.dateFilter"
				[formControl]="control"
				[formlyAttributes]="field"
				matInput
			/>
			<mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
			<mat-datepicker #datepickerRef></mat-datepicker>
			@if (to.description) {
				<mat-hint>{{ to.description }}</mat-hint>
			}
		</mat-form-field>
	`
})
export class DatepickerFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit {
	ngOnInit(): void {}

	get control() {
		return this.formControl as FormControl;
	}
}
