import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticatorService } from '../services/authenticator.service';

@Directive({
	selector: '[claim]',
	standalone: true
})
export class ClaimDirective implements OnInit {
	@Input() claim?: string | null;
	@Input() claimValue?: string | null;

	constructor(
		private elementRef: ElementRef,
		private authenticatorService: AuthenticatorService
	) {}
	ngOnInit(): void {
		if (!this.claim) return;
		if (!this.authenticatorService.hasClaim(this.claim)) {
			this.fail();
			return;
		}
		if (this.authenticatorService.hasClaimWithValue(this.claim, this.claimValue ?? 'true')) {
			this.success();
			return;
		}
		this.fail();
		return;
	}

	fail() {
		this.elementRef.nativeElement.remove();
		this.elementRef.nativeElement.innerHTML = '';
	}

	success() {}
}
