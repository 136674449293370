import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../services/lookup.service';
import { EntityService } from '../services/entity.service';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
	constructor(
		private entityService: EntityService,
		private decimalPipe: DecimalPipe
	) {}

	async transform(value: number, currencyId: any): Promise<string> {
		var currency = await this.entityService.getEntity('Currency', currencyId);
		if (!currency) {
			return `${this.decimalPipe.transform(value, '1.2-2')}`;
		}
		return `${this.decimalPipe.transform(value, '1.2-2')} ${currency.symbol}`;
	}
}
