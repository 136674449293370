import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import jwt_decode from 'jwt-decode';
import { EventBusService } from './event-bus.service';
import { Router } from '@angular/router';
import { AccessToken } from '../models/access-token.interface';
import { Response } from '../models/response.interface';
import { UserDto } from '../models/entities/user.model';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const USER = 'user';

@Injectable({
	providedIn: 'root'
})
export class AuthenticatorService {
	public userSubject: BehaviorSubject<UserDto | null> = new BehaviorSubject<UserDto | null>(null);
	public tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(
		private apiService: ApiService,
		private eventBusService: EventBusService,
		private router: Router
	) {
		this.init();
	}

	init() {
		if (this.tokenSubject.value && this.isAccessTokenExpired && !this.isRefreshTokenExpired) {
			this.postRefreshToken().subscribe((response) => {
				if (!response.hasError) {
					this.setCredentials(response.data);
				}
			});
		} else if (this.tokenSubject.value && this.isAccessTokenExpired && this.isRefreshTokenExpired) {
			this.logout();
			return;
		}

		var user = localStorage.getItem(USER);
		var token = localStorage.getItem(ACCESS_TOKEN);
		var refreshToken = localStorage.getItem(REFRESH_TOKEN);

		if (user && token && refreshToken) {
			this.userSubject.next(JSON.parse(user));
			this.tokenSubject.next(token);
			this.refreshTokenSubject.next(refreshToken);
		}
	}

	postRefreshToken(): Observable<Response<AccessToken>> {
		return this.apiService.post<Response<AccessToken>>(`identity/refresh-token`, {
			token: this.tokenSubject.value,
			refreshToken: this.refreshTokenSubject.value
		});
	}

	logout() {
		localStorage.removeItem(USER);
		localStorage.removeItem(ACCESS_TOKEN);
		localStorage.removeItem(REFRESH_TOKEN);

		this.userSubject.next(null);
		this.tokenSubject.next('');
		this.refreshTokenSubject.next('');

		this.eventBusService.emit({
			name: 'userLoggedOut',
			value: null
		});
		this.router.navigate(['/identity/login']);
	}

	setCredentials(tokenDto: AccessToken) {
		var decodedAccessToken = this.jwtDecode(tokenDto.token);
		var user: UserDto = {
			id: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
			userName: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
			email: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
			firstName: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
			lastName: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
			roles: decodedAccessToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
		};

		this.userSubject.next(user);
		this.tokenSubject.next(tokenDto.token);
		this.refreshTokenSubject.next(tokenDto.refreshToken);

		localStorage.setItem(USER, JSON.stringify(user));
		localStorage.setItem(ACCESS_TOKEN, tokenDto.token);
		localStorage.setItem(REFRESH_TOKEN, tokenDto.refreshToken);

		this.eventBusService.emit({ name: 'userLoggedOut', value: null });
	}

	jwtDecode(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}

	get isLogged(): boolean {
		return this.tokenSubject.value != '';
	}

	get isAccessTokenExpired(): boolean {
		var decodedAccessToken = this.jwtDecode(this.tokenSubject.value);
		var exp = decodedAccessToken['exp'];
		if (exp == null) return true;
		var date = new Date(0);
		date.setUTCSeconds(exp);
		return date.valueOf() < new Date().valueOf();
	}

	get isRefreshTokenExpired(): boolean {
		var decodedAccessToken = this.jwtDecode(this.refreshTokenSubject.value);
		var exp = decodedAccessToken['exp'];
		if (exp == null) return true;
		var date = new Date(0);
		date.setUTCSeconds(exp);
		return date.valueOf() < new Date().valueOf();
	}

	isInRole(role: string): boolean {
		return this.userSubject.value != null && this.userSubject.value.roles.some((x) => x == role);
	}
	hasClaim(claim: string): boolean {
		var decodedAccessToken = this.jwtDecode(this.tokenSubject.value);
		return decodedAccessToken[claim] != null;
	}
	hasClaimWithValue(claim: string, value: any): boolean {
		var decodedAccessToken = this.jwtDecode(this.tokenSubject.value);

		if (typeof value == 'string') {
			return decodedAccessToken[claim] == value;
		}

		return decodedAccessToken[claim]?.includes(value.toString());
	}
	getClaimValue(claim: string): any {
		var decodedAccessToken = this.jwtDecode(this.tokenSubject.value);
		return decodedAccessToken[claim];
	}
}
