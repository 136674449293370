import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticatorService } from '../services/authenticator.service';

@Injectable({
	providedIn: 'root'
})
class PermissionsService {
	constructor(
		private router: Router,
		private authenticatorService: AuthenticatorService
	) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authenticatorService.isLogged) {
			if (this.authenticatorService.isAccessTokenExpired) return this.fail();
			if (next.data['claims']) {
				var claims: { key: string; value: string }[] = next.data['claims'] as { key: string; value: string }[];
				for (var i = 0; i < claims.length; i++) {
					if (this.authenticatorService.hasClaimWithValue(claims[i].key, claims[i].value ?? 'true')) {
						return true;
					}
				}
				return this.fail();
			}
			return true;
		}
		return this.fail();
	}
	fail() {
		// navigate to login page with the return url
		var returnUrl = window.location.pathname;
		this.router.navigate(['/identity/login'], { queryParams: { returnUrl: returnUrl } });
		return false;
	}
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
	return inject(PermissionsService).canActivate(next, state);
};
