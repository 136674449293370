<a
	*ngIf="isLink(item) && !isFunction(item.route)"
	[claim]="item.claim"
	[claimValue]="item.claimValue"
	[class.hover:bg-hover]="!(isActive$ | async)?.(item)"
	[ngClass]="{
		'bg-primary-600 text-on-primary-600': (isActive$ | async)?.(item),
		'navigation-color': !(isActive$ | async)?.(item)
	}"
	[routerLink]="item.route"
	class="navigation-item"
	matRipple
>
	{{ item.label }}
</a>

<div
	(click)="item.route()"
	*ngIf="isLink(item) && isFunction(item.route)"
	[claim]="item.claim"
	[claimValue]="item.claimValue"
	[class.hover:bg-hover]="!(isActive$ | async)?.(item)"
	class="navigation-item navigation-color"
	matRipple
>
	{{ item.label }}
</div>

<ng-container *ngIf="isDropdown(item)">
	<div
		[class.hover:bg-hover]="!(isActive$ | async)?.(item)"
		[matMenuTriggerFor]="menu"
		[ngClass]="{
			'bg-primary-600 text-on-primary-600': (isActive$ | async)?.(item),
			'navigation-color': !(isActive$ | async)?.(item)
		}"
		class="navigation-item"
		matRipple
		[claim]="item.claim"
		[claimValue]="item.claimValue"
	>
		{{ item.label }}
	</div>

	<mat-menu #menu="matMenu" yPosition="below">
		<ng-container *ngFor="let child of item.children">
			@if (hasClaim(child.claim, child.claimValue ?? 'true')) {
				<a
					*ngIf="isLink(child) && !isFunction(child.route)"
					[ngClass]="{ 'text-primary-600': (isActive$ | async)?.(child) }"
					[routerLink]="child.route"
					class="navigation-menu-item"
					mat-menu-item
				>
					<mat-icon *ngIf="child.icon" [svgIcon]="child.icon" class="text-current"></mat-icon>
					<span> {{ child.label }}</span>
				</a>
			}
		</ng-container>
	</mat-menu>
</ng-container>

<ng-container *ngIf="isSubheading(item) && (item.children?.length ?? 0) > 0">
	<div
		[class.hover:bg-hover]="!(isActive$ | async)?.(item)"
		[matMenuTriggerFor]="menu"
		[ngClass]="{
			'bg-primary-600 text-on-primary-600': (isActive$ | async)?.(item),
			'navigation-color': !(isActive$ | async)?.(item)
		}"
		class="navigation-item"
		matRipple
		[claim]="item.claim"
		[claimValue]="item.claimValue"
	>
		{{ item.label }}
	</div>

	<mat-menu #menu="matMenu" yPosition="below">
		<ng-container *ngFor="let child of item.children">
			<a
				*ngIf="isLink(child) && !isFunction(child.route)"
				[claim]="child.claim"
				[claimValue]="child.claimValue"
				[ngClass]="{ 'text-primary-600': (isActive$ | async)?.(child) }"
				[routerLink]="child.route"
				class="navigation-menu-item"
				mat-menu-item
			>
				<mat-icon *ngIf="child.icon" [svgIcon]="child.icon" class="text-current"></mat-icon>
				<span>{{ child.label }}</span>
			</a>

			<div
				(click)="child.route()"
				*ngIf="isLink(child) && isFunction(child.route)"
				[claim]="child.claim"
				[claimValue]="child.claimValue"
				class="navigation-menu-item"
				mat-menu-item
			>
				<mat-icon *ngIf="child.icon" [svgIcon]="child.icon" class="text-current"></mat-icon>
				<span>{{ child.label }}</span>
			</div>

			<ng-container *ngIf="isDropdown(child)">
				<button
					[matMenuTriggerFor]="level1"
					[ngClass]="{ 'text-primary-600': (isActive$ | async)?.(child) }"
					class="navigation-menu-item"
					[claim]="child.claim"
					[claimValue]="child.claimValue"
					mat-menu-item
				>
					<mat-icon *ngIf="child.icon" [svgIcon]="child.icon" class="text-current"></mat-icon>
					<span>{{ child.label }}</span>
				</button>

				<mat-menu #level1="matMenu" yPosition="below">
					<ng-container *ngFor="let item of child.children">
						<ng-container [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="linkTemplate"></ng-container>

						<ng-container *ngIf="isDropdown(item)">
							<button
								[matMenuTriggerFor]="level2"
								[ngClass]="{ 'text-primary-600': (isActive$ | async)?.(item) }"
								class="navigation-menu-item"
								[claim]="item.claim"
								[claimValue]="item.claimValue"
								mat-menu-item
							>
								{{ item.label }}
							</button>

							<mat-menu #level2="matMenu" yPosition="below">
								<ng-container *ngFor="let child of item.children">
									<ng-container
										[ngTemplateOutletContext]="{ item: child }"
										[ngTemplateOutlet]="linkTemplate"
									></ng-container>

									<ng-container *ngIf="isDropdown(child)">
										<button
											[matMenuTriggerFor]="level3"
											[ngClass]="{
												'text-primary-600': (isActive$ | async)?.(child)
											}"
											[claim]="child.claim"
											[claimValue]="child.claimValue"
											class="navigation-menu-item"
											mat-menu-item
										>
											{{ child.label }}
										</button>

										<mat-menu #level3="matMenu" yPosition="below">
											<ng-container *ngFor="let item of child.children">
												<ng-container
													[ngTemplateOutletContext]="{ item: item }"
													[ngTemplateOutlet]="linkTemplate"
												></ng-container>

												<ng-container *ngIf="isDropdown(item)">
													<button
														[matMenuTriggerFor]="level4"
														[ngClass]="{
															'text-primary-600': (isActive$ | async)?.(item)
														}"
														class="navigation-menu-item"
														[claim]="item.claim"
														[claimValue]="item.claimValue"
														mat-menu-item
													>
														{{ item.label }}
													</button>

													<mat-menu #level4="matMenu" yPosition="below">
														<ng-container *ngFor="let child of item.children">
															<ng-container
																[ngTemplateOutletContext]="{ item: child }"
																[ngTemplateOutlet]="linkTemplate"
															></ng-container>

															<ng-container *ngIf="isDropdown(child)">
																<button
																	[matMenuTriggerFor]="level5"
																	[ngClass]="{
																		'text-primary-600': (isActive$ | async)?.(child)
																	}"
																	class="navigation-menu-item"
																	[claim]="child.claim"
																	[claimValue]="child.claimValue"
																	mat-menu-item
																>
																	{{ child.label }}
																</button>

																<mat-menu #level5="matMenu" yPosition="below">
																	<ng-container *ngFor="let item of child.children">
																		<ng-container
																			[ngTemplateOutletContext]="{ item: item }"
																			[ngTemplateOutlet]="linkTemplate"
																		></ng-container>
																	</ng-container>
																</mat-menu>
															</ng-container>
														</ng-container>
													</mat-menu>
												</ng-container>
											</ng-container>
										</mat-menu>
									</ng-container>
								</ng-container>
							</mat-menu>
						</ng-container>
					</ng-container>
				</mat-menu>
			</ng-container>
		</ng-container>
	</mat-menu>

	<ng-template #linkTemplate let-item="item">
		<a
			*ngIf="isLink(item) && !isFunction(item.route)"
			[claim]="item.claim"
			[claimValue]="item.claimValue"
			[ngClass]="{ 'text-primary-600': (isActive$ | async)?.(item) }"
			[routerLink]="item.route"
			class="navigation-menu-item"
			mat-menu-item
			>{{ item.label }}</a
		>

		<div
			(click)="item.route()"
			*ngIf="isLink(item) && isFunction(item.route)"
			[claim]="item.claim"
			[claimValue]="item.claimValue"
			[ngClass]="{ 'text-primary-600': (isActive$ | async)?.(item) }"
			class="navigation-menu-item"
			mat-menu-item
		>
			{{ item.label }}
		</div>
	</ng-template>
</ng-container>
