import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

@Component({
	selector: 'mat-wrapper',
	standalone: true,
	imports: [FormlyModule, ReactiveFormsModule, MatFormFieldModule],
	template: `
		<mat-form-field>
			<ng-container #fieldComponent></ng-container>
			<mat-hint>{{ to.description }}</mat-hint>
		</mat-form-field>
	`
})
export class MatFieldWrapper extends FieldWrapper {}
