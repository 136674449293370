import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatorService } from '../services/authenticator.service';

export const tokenInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
	var authenticatorService = inject(AuthenticatorService);
	var token = authenticatorService.tokenSubject.value;

	if (token) {
		const cloned = req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
		return next(cloned);
	} else {
		return next(req);
	}
};
