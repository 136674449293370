import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class TranslatorService {
	constructor(private translateService: TranslateService) {}

	init(): void {
		var lang = localStorage.getItem('lang');
		if (!lang) {
			lang = 'tr';
			localStorage.setItem('lang', lang);
		}
		this.translateService.addLangs(['en', 'tr']);
		this.translateService.setDefaultLang('tr');
		this.translateService.use(lang);
	}

	changeLanguage(lang: string): void {
		this.translateService.use(lang);
		localStorage.setItem('lang', lang);
	}

	translate(key: string): string {
		if (!key) return '';
		return this.translateService.instant(key);
	}

	get currentLanguage(): string {
		return this.translateService.currentLang;
	}
}
