import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { AuthenticatorService } from '../services/authenticator.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export const unauthorizedErrorInterceptor: HttpInterceptorFn = (
	req: HttpRequest<any>,
	next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
	var authenticatorService = inject(AuthenticatorService);
	var matSnackBar = inject(MatSnackBar);
	return next(req).pipe(
		catchError((error) => {
			if (error instanceof HttpErrorResponse && !req.url.includes('identity/login') && error.status === 401) {
				matSnackBar.open('Oturum süresi doldu, lütfen tekrar giriş yapın', 'OK', {
					duration: 5000
				});
				authenticatorService.logout();
				throw new Error('Unauthorized');
			}
			throw error;
		})
	);
};
