@if (is2faEnabled) {
	<div class="flex flex-col gap-4">
		<div class="flex flex-col">
			<p class="text-2xl">Iki Faktorlu Dogrulama Acik</p>
			<p class="text-sm">
				Iki faktorlu dogrulama acik oldugu icin hesabiniza girerken iki faktorlu dogrulama kodu girmeniz gerekmektedir.
			</p>
		</div>

		@if (authenticatorVerifyLoading) {
			<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
		}
		<div [formGroup]="form" class="p-6 flex flex-col gap-4">
			<div class="flex flex-col">
				<mat-form-field class="flex-1">
					<mat-label>Verification Code</mat-label>
					<input formControlName="code" matInput />
				</mat-form-field>
			</div>
			<button (click)="authenticatorVerify(false)" color="primary" mat-raised-button type="button">KALDIR</button>
		</div>
	</div>
} @else {
	@if (authenticatorSetupLoading) {
		<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
	}
	@if (!authenticatorSetupLoading && authenticatorSetup) {
		<p>To use an authenticator app go through the following steps:</p>
		<ol class="list">
			<li>
				<p>
					Download a two-factor authenticator app Google Authenticator for
					<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
						>Android</a
					>
					and
					<a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a>.
				</p>
			</li>
			<li>
				<p>
					Scan the QR Code or enter this key
					<kbd> {{ authenticatorSetup.sharedKey }} </kbd> into your two factor authenticator app. Spaces and casing do
					not matter.
				</p>
			</li>
			<li>
				<p>
					Once you have scanned the QR code or input the key above, your two factor authentication app will provide you
					with a unique code. Enter the code in the confirmation box below.
				</p>
			</li>
		</ol>
		<qrcode [qrdata]="authenticatorSetup.qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

		@if (authenticatorVerifyLoading) {
			<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
		}
		<div [formGroup]="form" class="p-6 flex flex-col gap-4">
			<div class="flex flex-col">
				<mat-form-field class="flex-1">
					<mat-label>Verification Code</mat-label>
					<input formControlName="code" matInput />
				</mat-form-field>
			</div>
			<button (click)="authenticatorVerify(true)" color="primary" mat-raised-button type="button">ONAYLA</button>
		</div>
	}
}
