import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class EventBusService {
	private subject$ = new Subject<{
		name: string;
		value: any;
	}>();

	constructor() {}

	emit(event: { name: string; value: any }) {
		this.subject$.next(event);
	}

	on(eventName: string, action: any): Subscription {
		return this.subject$
			.pipe(
				filter((e: { name: string; value: any }) => e.name === eventName),
				map((e: { name: string; value: any }) => e['value'])
			)
			.subscribe(action);
	}
}
