import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';

@Component({
	selector: 'vex-toolbar-user',
	templateUrl: './toolbar-user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatRippleModule, MatIconModule]
})
export class ToolbarUserComponent implements OnInit {
	dropdownOpen: boolean = false;

	loggedUserName: string = '';
	constructor(
		private popover: VexPopoverService,
		private cd: ChangeDetectorRef,
		private authenticatorService: AuthenticatorService
	) {}

	ngOnInit() {
		var user = this.authenticatorService.userSubject.value;
		if (user) {
			this.loggedUserName = user.firstName + ' ' + user.lastName;
		}
	}

	showPopover(originRef: HTMLElement) {
		this.dropdownOpen = true;
		this.cd.markForCheck();

		const popoverRef = this.popover.open({
			content: ToolbarUserDropdownComponent,
			origin: originRef,
			offsetY: 12,
			position: [
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom'
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top'
				}
			]
		});

		popoverRef.afterClosed$.subscribe(() => {
			this.dropdownOpen = false;
			this.cd.markForCheck();
		});
	}
}
