import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { IconsService } from '../services/icons.service';

export function provideIcons(): Array<Provider | EnvironmentProviders> {
	return [
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(IconsService),
			multi: true
		}
	];
}
