import { ChangeDetectorRef, Component, ElementRef, NgIterable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Observable, Subscription, map } from 'rxjs';
import { CustomFormlyFieldConfig } from './custom-formly-field.interface';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, NgIf } from '@angular/common';
import { KedyPipesModule } from '../../pipes/kedy-pipes.module';

@Component({
	selector: 'formly-cached-select',
	standalone: true,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,

		NgIf,
		CommonModule,
		KedyPipesModule
	],
	template: `
		<ng-container>
			<mat-form-field appearance="outline" style="width: 100%;">
				<mat-label> {{ field.props?.label }} </mat-label>

				<mat-select
					#selectInput
					[formControl]="control"
					[formlyAttributes]="field"
					[multiple]="field.props?.multiple"
					(opened)="onOpened()"
					[tabIndex]="field.props?.tabindex"
				>
					<input
						matInput
						#searchInput
						[formControl]="searchCtrl"
						class="mat-mdc-option"
						style="width: calc(100% - 32px);"
					/>

					<mat-option *ngIf="(!field.props || !field.props.required) && !searchCtrl.value" [value]="null">
						Seçiniz
					</mat-option>

					<ng-container *ngIf="observable | async as optionList; else loading">
						<mat-option *ngFor="let option of optionList | search: searchCtrl.value" [value]="option.value">
							{{ option.label }}
						</mat-option>
					</ng-container>
				</mat-select>

				<ng-template #loading>
					<mat-spinner diameter="20"></mat-spinner>
				</ng-template>
			</mat-form-field>
		</ng-container>
	`
})
export class CachedSelectFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit {
	searchCtrl = new FormControl();
	@ViewChild('searchInput') searchInput!: ElementRef;
	@ViewChild('selectInput') selectInput!: MatSelect;

	observable!: Observable<{ value: any; label: string }[]>;

	async ngOnInit() {
		if (!this.field.props) {
			throw new Error('props is required');
		}

		if (!this.field.props?.valueProp) {
			this.field.props.valueProp = 'id';
		}
		if (!this.field.props?.labelProp) {
			this.field.props.labelProp = 'name';
		}

		if (this.field.props?.observable) {
			this.setObservable();
		} else {
			throw new Error('observable is required');
		}
	}

	setObservable() {
		if (!this.field.props?.observable) {
			throw new Error('observable is required');
		}
		var observable = this.field.props.observable;
		this.observable = observable.pipe(
			map((items: any[]) => {
				if (this.field.props?.labelFunc)
					return items.map((item: any) => {
						if (!this.field.props?.labelFunc) {
							throw new Error('labelFunc is required');
						}
						return {
							value: item[this.field.props.valueProp ?? 'id'],
							label: this.field.props.labelFunc(item)
						};
					});

				return items.map((item: any) => {
					return {
						value: item[this.field.props?.valueProp ?? 'id'],
						label: item[this.field.props?.labelProp ?? 'name']
					};
				});
			})
		);
	}

	onOpened() {
		this.searchCtrl.setValue('');
		this.searchInput.nativeElement.focus();
	}

	get control() {
		return this.formControl as FormControl;
	}
}
