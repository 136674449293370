import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgIterable,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from './custom-formly-field.interface';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Observable, ReplaySubject, Subject, Subscription, map, take, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { SPACE } from '@angular/cdk/keycodes';
import { Response } from '../../models/response.interface';
import { ApiService } from '../../services/api.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, NgIf } from '@angular/common';
import { KedyPipesModule } from '../../pipes/kedy-pipes.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'formly-http-select',
	standalone: true,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,
		NgxMatSelectSearchModule,
		MatIconModule,

		NgIf,
		CommonModule,
		KedyPipesModule
	],
	template: `
		<ng-container>
			<mat-form-field appearance="outline" style="width: 100%;">
				<mat-label> {{ field.props?.label }} </mat-label>

				<mat-select
					#input
					[multiple]="field.props?.multiple"
					[required]="field.props?.required ?? false"
					[formControl]="control"
					[formlyAttributes]="field"
					(opened)="onOpened()"
				>
					<mat-option>
						<ngx-mat-select-search
							[closeSvgIcon]="'mat:close'"
							[formControl]="searchCtrl"
							placeholderLabel="Ara..."
						></ngx-mat-select-search>
					</mat-option>

					<mat-option *ngIf="loadingItems">
						<div class="flex items-center justify-between">
							<span> Yükleniyor... </span>
							<span>
								<mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
							</span>
						</div>
					</mat-option>

					<mat-option *ngIf="anyErrors" (click)="loadItems()">
						<div class="flex items-center justify-between text-red-500">
							<span>Bir hata oluştu</span>
							<button mat-icon-button>
								<mat-icon svgIcon="mat:refresh"></mat-icon>
							</button>
						</div>
					</mat-option>

					<mat-option *ngIf="!field.props?.required && !searchCtrl.value && !loadingItems && !anyErrors" [value]="null">
						Seçiniz
					</mat-option>

					<mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
						{{ item.label }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container>
	`
})
export class HttpSelectFieldComponent
	extends FieldType<CustomFormlyFieldConfig>
	implements OnInit, OnDestroy, AfterViewInit
{
	@ViewChild('input') input!: MatSelect;
	searchCtrl = new FormControl();

	items: any[] | undefined;
	loadingItems: boolean = false;
	anyErrors: boolean = false;

	filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>();
	_onDestroy = new Subject<void>();
	constructor(
		private apiService: ApiService,
		private cdr: ChangeDetectorRef
	) {
		super();
	}

	async ngOnInit() {
		if (!this.field.props) {
			throw new Error('props is required');
		}

		if (!this.field.props.valueProp) {
			this.field.props.valueProp = 'id';
		}
		if (!this.field.props.labelProp) {
			this.field.props.labelProp = 'name';
		}

		if (!this.field.props.http?.parentProp) this.loadItems();

		if (this.field.props.http?.parentProp) {
			var parentProp: string = this.field.props.http?.parentProp;
			this.form.get(parentProp)?.valueChanges.subscribe((value: any) => {
				if (!value) return;
				this.loadItems();
			});
		}

		this.searchCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterItems();
		});
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	ngAfterViewInit(): void {
		this.setInitialValue();
	}

	setInitialValue() {
		this.filteredItems.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			this.input.compareWith = (o1: any, o2: any) => {
				return o1 && o2 && o1 === o2;
			};
		});
	}

	filterItems() {
		if (!this.items) {
			return;
		}
		// get the search keyword
		let search = this.searchCtrl.value;
		if (!search) {
			this.filteredItems.next(this.items.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the banks
		this.filteredItems.next(this.items.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
	}

	loadItems() {
		if (!this.field.props) {
			throw new Error('props is required');
		}
		if (!this.field.props.http) {
			throw new Error('http prop is required');
		}
		this.loadingItems = true;
		this.anyErrors = false;
		var url = this.field.props.http.url;
		if (this.field.props.http.parentProp) {
			var parentProp: string = this.field.props.http.parentProp;
			var parentPropControl = this.form.get(parentProp);
			if (parentPropControl) {
				var parentPropValue = parentPropControl.value;
				url = url + '?' + this.field.props.http.parentPropQuery + '=' + parentPropValue;
			}
		}
		this.apiService
			.request<Response<any>>(this.field.props.http.method || 'get', url, this.field.props.http.body)
			.subscribe({
				next: (response: HttpEvent<Response<any>>) => {
					if (response instanceof HttpResponse) {
						if (response.body?.data) {
							var items = this.setItems(response.body.data);
							this.items = items;
							if (this.items) {
								this.filteredItems.next(this.items.slice());
								this.loadingItems = false;
							}
						}
						this.cdr.detectChanges();
					}
				},
				error: (error: any) => {
					this.anyErrors = true;
					this.loadingItems = false;
					this.cdr.detectChanges();
				},
				complete: () => {
					this.loadingItems = false;
					this.cdr.detectChanges();
				}
			});
	}

	setItems(items: any[]) {
		if (!this.field.props) {
			throw new Error('props is required');
		}
		var valueProp: string = this.field.props.valueProp ?? 'id';

		var mappedItems;
		if (this.field.props.labelFunc) {
			var labelFunc: (item: any) => string = this.field.props.labelFunc;
			return items.map((item: any) => {
				return {
					value: item[valueProp],
					label: labelFunc(item)
				};
			});
		}

		if (this.field.props.labelProp) {
			var labelProp = this.field.props.labelProp ?? 'name';
			mappedItems = items.map((item: any) => {
				return {
					value: item[valueProp],
					label: item[labelProp]
				};
			});
		}

		if (!this.field.props.labelFunc && !this.field.props.labelProp) {
			throw new Error('labelFunc or labelProp is required');
		}

		return mappedItems;
	}

	onOpened() {
		this.searchCtrl.setValue('');
	}

	onClosed() {
		console.warn(this.input.selected);
	}

	get control() {
		return this.formControl as FormControl;
	}
}
