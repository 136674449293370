<vex-secondary-toolbar current="i18n:PAGES.PROFILE">
	<vex-breadcrumbs [crumbs]="['i18n:PAGES.DASHBOARD', 'i18n:PAGES.PROFILE']" class="flex-auto"></vex-breadcrumbs>

	<button class="ml-2" color="primary" mat-icon-button type="button">
		<mat-icon svgIcon="mat:more_vert"></mat-icon>
	</button>
</vex-secondary-toolbar>

<vex-page-layout mode="card">
	<vex-page-layout-header class="h-6"></vex-page-layout-header>
	<vex-page-layout-content class="container">
		<div class="card">
			<div class="p-4">
				<h2 class="m-0 title mb-6">Two Factor Authentication</h2>
				<tfa-setup></tfa-setup>
			</div>
		</div>
	</vex-page-layout-content>
</vex-page-layout>
